import { map, first, switchMap } from 'rxjs/operators';
import { PageComponentNames } from 'behavior/pages/componentNames';
import { initSystemPageContent, loadSystemPageQuery } from 'behavior/pages/system';
import { loadCustomerSpecialOffersPageQuery, loadCustomerSpecialOffersPageQuery2  } from './queries';

export default (routeData, state$, { api }) => {
  //console.log('The api :', api);
  //console.log('The query :', loadCustomerSpecialOffersPageQuery );
  //console.log('The query :', loadCustomerSpecialOffersPageQuery2 );

  return api.graphApi(loadCustomerSpecialOffersPageQuery2).pipe(
    map(({ pages }) => {
      const page = pages.specialOffers;
      if (!page)
        return null;

      page.component = PageComponentNames.SpecialOffers; //always required.

      //        //old draft
      //  //return { page };

      return {
        page: {
          ...page,
          id: 'specialOffers',
          lastViewedEnabled: false,
          relevanceAvailable: true,
          component: PageComponentNames.SpecialOffers,
        },
      };
    }),
    initSystemPageContent(),
  );
};