import { CUSTOMERSPECIALOFFER_GET } from './actions';
import { switchMap, pluck, mapTo } from 'rxjs/operators';
import { loadSpecialOffersQuery } from './queries';
import { merge, of } from 'rxjs';
import { catchApiErrorWithToast, retryWithToast } from 'behavior/errorHandling';
import { unlockForm, FormLockKeys } from 'behavior/pages';
import { ofType } from 'redux-observable';
import { Epic } from 'behavior/types';

export default (action$, state$, { api, logger }) => {
  const getCustomerSpecialOffers$ = action$.pipe(
    ofType(CUSTOMERSPECIALOFFER_GET),
    pluck('payload'),
    switchMap(({ value, index }) => {

      const apiCall = api.graphApi(loadSpecialOffersQuery).pipe(
        switchMap((response, index) => {

          console.log("[3] STREAM B I think this is called with the arguments from the grpah api", response, index, arguments);
          return of({
            type: 'USER/RECEIVED_NEW_SPECIAL_OFFERS',
            customerId: response?.viewer?.customer?.id,
            payload: response?.viewer?.customer?.customerSpecialOffer
          });

        }),
      );
      return apiCall;
    },
    ));
return getCustomerSpecialOffers$;
};
