import { memo } from 'react';
import PropTypes from 'prop-types';
import { Placeholder } from 'components/primitives/placeholders';
import styles from '../product/Details.module.scss';
import stylesPrice from 'components/primitives/product/ProductPrice.module.scss';
import { useSanaTexts } from 'components/sanaText';
import { getCurrency, getFormatPrice, formatAsPercentage } from 'utils/format';
import { useSelector, shallowEqual } from 'react-redux';
import { Col, Row } from 'components/primitives/grid';
import { SimpleText, RichText } from 'components/sanaText';
import { useCurrencyInfo } from 'utils/hooks';
import moment from 'moment';

const SpecialOfferDetails = ({ unusedProdId ,specialOffers }) => {

  const { texts: [
    specialOfferNameLbl,
    specialOfferDescriptionLbl,
    specialOfferLineDiscountLbl,
    specialOfferLineDiscountTemplate,
  ], loaded } = useSanaTexts([
    'SpecialOfferName_Label',
    'SpecialOfferDescription_Label',
    'SpecialOfferLineDiscount_Label',
    'SpecialOfferLineDiscount_DiscountPercentage',
  ]);

  const currencyInfo = useCurrencyInfo();
  const currencySymbol = getCurrency(currencyInfo.id, currencyInfo.cultureName);
  const pricePlaceholder = <Placeholder className={styles.pricePlaceholder} />;
  let isValidTerm = false;

  const { customer } = useSelector(({ user: { customer } }) => ({ customer }), shallowEqual);
  const specialOfferFiltered = customer ? specialOffers.filter(x => x.specialOfferItems.filter(y => y.specialOfferItemId === unusedProdId)[0]) : [];
  const specialOffersValidated = customer && specialOfferFiltered !== [] ? specialOfferFiltered.filter(x => validateSpof(x)) : [];
  function validateSpof(specialOffer) {
    //check of product wel deel uitmaakt van het special offer
    if (specialOffer) {
      const today = moment().toDate();
      const SPOFstartDate = moment(new Date(specialOffer.specialOfferStartDate)).toDate();
      const SPOFendDate = moment(new Date(specialOffer.specialOfferEndDate)).toDate();
      isValidTerm = today > SPOFstartDate && today < SPOFendDate ? true : false;
      return specialOffer;
    } else
      return null;
  }

  return (
  <>
      {customer && specialOffersValidated.length > 0 && isValidTerm &&
          <Row className={styles.row} crossAxisAlign="center">
            <Col xs={3} lg={2} className={styles.ellipsis} id="specialOffer_Label">
            {/*<SimpleText textKey="SpecialOffer" placeholder={pricePlaceholder} />*/}
              {
                specialOffersValidated.map((specialOffer, index) => (
                  <div>{specialOffer.specialOfferName}</div>
                ))
              }
            </Col>
            <Col xs={9} lg={10} className={styles.field}>
              <Row className={styles.prices}>
                <Col xs="auto" className={styles.row} crossAxisAlign="center">
                <div title={specialOfferDescriptionLbl}>
                  {specialOffersValidated.map((specialOffer, index) => (
                    <i className="visually-hidden">{specialOfferDescriptionLbl} {specialOffer.specialOfferDescription}</i>
                  ))}
                  <span aria-hidden suppressHydrationWarning>
                    {specialOffersValidated.map((specialOffer, index) => (
                      <div>
                      <span> {specialOffer.specialOfferDescription} </span>
                      <span className={`${stylesPrice.discountPercentage} discount-percentage`}>{specialOffer.specialOfferLineDiscount}</span>
                      </div>
                    ))
                    }
                  </span>
                  </div>
                <div className={`${stylesPrice.discountPercentage} discount-percentage`} title={specialOfferLineDiscountLbl}>
                  {specialOffersValidated.map((specialOffer, index) => (
                    <i className="visually-hidden">{specialOfferLineDiscountLbl} {specialOffer.specialOfferLineDiscount + '%'}</i>
                  ))}
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
      }
      </>
  );
};

SpecialOfferDetails.propTypes = {
  unusedProdId: PropTypes.string.isRequired,
  specialOffers: PropTypes.array,
};

export default memo(SpecialOfferDetails);