import styles from './ProductSuggestions.module.scss';
import { memo } from 'react';
import btnStyles from 'components/primitives/buttons/Button.module.scss';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { useSanaButton } from 'components/primitives/buttons';
import { Link } from 'components/primitives/links';
import { withAbilitiesAndRouteChecker } from 'components/primitives/checkers';
import { AbilityTo } from 'behavior/user/constants';
import { RouteName } from 'routes';
import { CartSuggestionIcon } from 'components/primitives/icons';

const ProductSuggestionsBase = ({ hideLabel, className = '' }) => {
  const { text, title } = useSanaButton('GetProductSuggestions', undefined, undefined, 'GetProductSuggestions');
  const shoppingCartSuggestionPage = useSelector(({ settings }) => settings.shoppingCartSuggestionPage);

  if (shoppingCartSuggestionPage == null)
    return null;

  const hideLabelClassName = hideLabel ? `${styles.hideLabel} ` : '';

  const isHidden = true;

  return (
     <>
      {!isHidden && (
        <Link
          title={hideLabel ? title : undefined}
          to={shoppingCartSuggestionPage.to}
          url={shoppingCartSuggestionPage.url}
          className={`${hideLabelClassName}${styles.suggestionsBtn} ${btnStyles.btnWhite} ${className}`}
        >
          <CartSuggestionIcon className={styles.icon} aria-hidden />
          {!hideLabel && <span className="btn-cnt">{text}</span>}
        </Link>
      )}
    </>
  );
};

ProductSuggestionsBase.propTypes = {
  hideLabel: PropTypes.bool,
  className: PropTypes.string,
};

export default withAbilitiesAndRouteChecker(
  memo(ProductSuggestionsBase), 
  [RouteName.BasketPage, RouteName.Checkout], 
  [AbilityTo.ViewProductSuggestions],
);
