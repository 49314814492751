import styles from './Summary.module.scss';
import linkStyles from 'components/primitives/links/Link.module.scss';
import btnStyles from 'components/primitives/buttons/Button.module.scss';
import { memo } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'components/primitives/links';
import { UseSanaButton } from 'components/primitives/buttons';
import { SimpleText } from 'components/sanaText';
import { routesBuilder } from 'routes';
import { AbilityState } from 'behavior/user/constants';
import { abilityPropType } from 'behavior/user';
import { useRoutesChecker } from 'components/primitives/checkers';
import { RouteName } from 'routes';
import { DocumentType } from 'behavior/documents';

const footerBtnClassName = `${btnStyles.btn} ${btnStyles.btnBase} ${btnStyles.btnAction} ${styles.footerBtn}`;

const checkoutRoutes = [
  RouteName.Checkout,
];

const Footer = ({ editDocumentType, createOrderAbility, isBlocked, createQuoteAbility, salesAgreementId }) => {
  const canSubmitOrder = createOrderAbility !== AbilityState.NotAvailable;
  const canCreateQuote = createQuoteAbility !== AbilityState.NotAvailable;

  const isCheckoutRoute = useRoutesChecker(checkoutRoutes);
  if (isCheckoutRoute)
    return (
      <div className={styles.panelFooter}>
        <Link className={`${linkStyles.arrowed} ${styles.footerLink}`} to={routesBuilder.forBasket}>
          <SimpleText textKey="ViewBasket" />
        </Link>
      </div>
    );

  const isEditQuote = editDocumentType === DocumentType.Quote,
    isEditOrder = editDocumentType === DocumentType.Order;

  return (
    <div className={styles.panelFooter}>
      <div>
        <Link className={`${linkStyles.arrowed} ${styles.footerLink}`} to={routesBuilder.forBasket}>
          <SimpleText textKey="ViewBasket" />
        </Link>
      </div>
      {(isEditOrder || canSubmitOrder && !isEditQuote) &&
        <div>
          <UseSanaButton textKey="Checkout" dontWait>
            {text => (
              <Link
                to={routesBuilder.forBasket}
                className={footerBtnClassName}
                disabled={isBlocked}
              >
                <span className="btn-cnt">{text}</span>
              </Link>
            )}
          </UseSanaButton>
        </div>
      }
      {(isEditQuote || canCreateQuote && !isEditOrder && !canSubmitOrder) && !salesAgreementId &&
        <UseSanaButton textKey="GetQuote" dontWait>
          {text => (
            <Link
              to={routesBuilder.forBasket}
              className={footerBtnClassName}
            >
              <span className="btn-cnt">{text}</span>
            </Link>
          )}
        </UseSanaButton>
      }
    </div>
  );
};

Footer.propTypes = {
  editDocumentType: PropTypes.string,
  createOrderAbility: abilityPropType.isRequired,
  isBlocked: PropTypes.bool,
  createQuoteAbility: abilityPropType.isRequired,
  salesAgreementId: PropTypes.string,
};

export default memo(Footer);
