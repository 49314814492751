export const PRODUCTLIST_PRODUCTS_GENERALINFO_REQUESTED = 'PRODUCTLIST_PRODUCTS_GENERALINFO_REQUESTED';
export const requestProductsGeneralInfo = (listPageId, options, appendProducts = false) => ({
  type: PRODUCTLIST_PRODUCTS_GENERALINFO_REQUESTED,
  payload: { listPageId, options, appendProducts },
});

export const PRODUCTLIST_PRODUCTS_GENERALINFO_LOADED = 'PRODUCTLIST_PRODUCTS_GENERALINFO_LOADED';
export const productsGeneralInfoLoaded = (products, appendProducts, pageSize, totalCount, sorting) => ({
  type: PRODUCTLIST_PRODUCTS_GENERALINFO_LOADED,
  payload: {
    products,
    appendProducts,
    pageSize,
    totalCount,
    sorting,
  },
});

export const PRODUCTLIST_CALCULATED_FIELDS_REQUESTED = 'PRODUCTLIST_CALCULATED_FIELDS_REQUESTED';
export const requestCalculatedFields = (options, variantsOnly) => ({
  type: PRODUCTLIST_CALCULATED_FIELDS_REQUESTED,
  payload: { options, variantsOnly },
});

export const PRODUCTLIST_PRODUCTS_UPDATED = 'PRODUCTLIST_PRODUCTS_UPDATED';
export const productsUpdated = products => ({
  type: PRODUCTLIST_PRODUCTS_UPDATED,
  payload: products,
});

export const PRODUCTLIST_VARIANTS_GENERALINFO_REQUESTED = 'PRODUCTLIST_VARIANTS_GENERALINFO_REQUESTED';
export const requestVariantsGeneralInfo = options => ({
  type: PRODUCTLIST_VARIANTS_GENERALINFO_REQUESTED,
  payload: { options },
});

/*
 * ADU JLC - Special Offers
*/
//export const getSpecialOffers = (accountId: string) => ({
//  type: PRODUCTLIST_GET_SPECIALOFFERS,
//  payload: {
//    id: accountId
//  },
//});