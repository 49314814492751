export const enum PageComponentNames {
  Basket = 'basket',
  Product = 'productDetails',
  ProductList = 'productList',
  News = 'news',
  NewsDetails = 'newsItem',
  Represent = 'represent',
  LastViewedProducts = 'lastViewedProducts',
  Orders = 'orders',
  Quotes = 'quotes',
  Invoices = 'invoices',
  ReturnOrders = 'returnOrders',
  CreditNotes = 'creditNotes',
  ReturnReceipts = 'returnReceipts',
  Shipments = 'shipments',
  OrderAuthorizations = 'orderAuthorizations',
  Order = 'order',
  Quote = 'quote',
  Invoice = 'invoice',
  ReturnOrder = 'returnOrder',
  CreateDocFreeReturnOrder = 'createDocFreeReturnOrder',
  CreateDocBasedReturnOrder = 'createDocBasedReturnOrder',
  CreditNote = 'creditNote',
  ReturnReceipt = 'returnReceipt',
  Shipment = 'shipment',
  OrderTemplates = 'orderTemplates',
  SalesAgreements = 'salesAgreements',
  SalesAgreement = 'SalesAgreement',
  NotFound = 'pageNotFound',
  Content = 'content',
  Search = 'Search',
  ProductsWithCategory = 'PLP_category',
  Offline = 'Offline',
  SubAccount = 'subAccount',
  SubAccounts = 'subAccounts',
  CreateProspect = 'createProspect',
  ResetPassword = 'resetPassword',
  Registration = 'registration',
  Login = 'login',
  ClosedStoreLogin = 'closedStoreLogin',
  ClosedStoreForgotPassword = 'closedStoreForgotPassword',
  ClosedStoreResetPassword = 'closedStoreResetPassword',
  ForgotPassword = 'forgotPassword',
  ProductComparison = 'productComparison',
  Checkout = 'checkout',
  OrderSubmit = 'orderSubmit',
  OrderFailed = 'orderFailed',
  OrderCancelled = 'orderCancelled',
  PaymentError = 'paymentError',
  EditProfile = 'editProfile',
  Error = 'errorBanner',
  InvoicePayment = 'invoicePayment',
  OrderPayment = 'orderPayment',
  PaymentSuccessful = 'paymentSuccessful',
  PaymentCancelled = 'paymentCancelled',
  PaymentFailed = 'paymentFailed',
  WishList = 'wishList',
  ChangePassword = 'changePassword',
  Faq = 'faq',
  MyAccount = 'myAccount',
  ContactUs = 'contactUs',
  Subscribe = 'subscribe',
  Unsubscribe = 'unsubscribe',
  SpecialOffers = 'specialOffers',
}
