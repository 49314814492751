export const USER_LOGIN = 'USER_LOGIN';
export const login = (authData, redirectTo) => ({ type: USER_LOGIN, payload: { authData, redirectTo } });

export const LOGIN_FAILED = 'USER_LOGIN_FAILED';
export const loginFailed = () => ({ type: LOGIN_FAILED });

export const LOGIN_FAILED_RESET = 'USER_LOGIN_FAILED_RESET';
export const resetLoginFailed = () => ({ type: LOGIN_FAILED_RESET });

export const USER_REGISTER = 'USER_REGISTER';
export const register = registrationData => ({ type: USER_REGISTER, payload: { registrationData } });

export const USER_CREATE_PROSPECT = 'USER_CREATE_PROSPECT';
export const createProspect = prospectData => ({ type: USER_CREATE_PROSPECT, payload: { prospectData } });

export const USER_AUTHENTICATED = 'USER_AUTHENTICATED';
export const authenticated = user => ({ type: USER_AUTHENTICATED, payload: user });

export const USER_LOGOUT = 'USER_LOGOUT';
export const logout = () => ({ type: USER_LOGOUT });

export const USER_ABILITIES_REQUESTED = 'USER_ABILITIES_REQUESTED';
export const loadUserAbilities = keys => ({ type: USER_ABILITIES_REQUESTED, payload: keys });

export const USER_ABILITIES_LOADED = 'USER_ABILITIES_LOADED';
export const userAbilitiesLoaded = userAbilities => ({ type: USER_ABILITIES_LOADED, payload: userAbilities });

export const REPRESENT_CUSTOMER = 'USER_REPRESENT';
export const representCustomer = (id, shopAccountType) => ({ type: REPRESENT_CUSTOMER, payload: { id, shopAccountType, redirectBack: true } });
export const stopRepresentingCustomer = () => ({ type: REPRESENT_CUSTOMER, payload: { id: null } });

export const IMPERSONATION_FAILED = 'IMPERSONATION_FAILED';
export const impersonationFailed = () => ({ type: IMPERSONATION_FAILED });

export const IMPERSONATION_FAILED_RESET = 'IMPERSONATION_FAILED_RESET';
export const resetImpersonationFailed = () => ({ type: IMPERSONATION_FAILED_RESET });

export const USER_PROFILE_UPDATED = 'USER_PROFILE_UPDATED';
export const userProfileUpdated = user => ({ type: USER_PROFILE_UPDATED, payload: user });

export const USER_RELOAD = 'USER_RELOAD';
export const reloadUser = () => ({ type: USER_RELOAD });

export const USER_RECEIVED_SPECIALOFFERS = 'USER_RECEIVED_SPECIALOFFERS';
export const receivedSpecialOffers = offers => ({ type: USER_RECEIVED_SPECIALOFFERS, payload: offers });
